import React from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
import { graphql } from "gatsby"
import ImageCarousel from "../components/imageCarousel.js";
import Intro from "../components/intro.js";

const Index = ({ data }) => {

  const images = data.allMainJson.edges.map(({ node }) => node.image.carouselImage);

  return (
    <Layout>
      <Seo title="Edward Leibovitz" />
      <ImageCarousel images={images} />
      <Intro />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMainJson {
        edges {
          node {
            id
            name
            image {
              carouselImage: childImageSharp {
                gatsbyImageData(height:500, placeholder: BLURRED, formats: [WEBP, AUTO]),
              }
            }
          }
        }
      }
  }
`

export default Index