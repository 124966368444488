import React from "react"

const Intro = () => {
    return (
        <>
            <p></p>
            <p className="small">The Romanian born artist Edward Leibovitz produced highly individual glass creations from the early 1970s. He combined a purely artistic pursuit and an intense curiosity towards his material with an experimental approach to technique. Over the years he developed and perfected his own techiques, enabling him to turn his material to his hand.</p>
            <p className="small">He used a mixture of diamond engraving, carving and sandblasting, and, more recently, laser technology.
                Leibovitz often used glass in combination with other materials such as bronze and marble, playing the different qualities of the materials off against each other.</p>
            <p className="small">As an artist he received international recognition, his work having been the subject of several one-man exhibitions, and being included in major museum collections all over the world.</p>
        </>
    )
}
export default Intro
